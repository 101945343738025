import AbstractAnimal from "./AbstractAnimal";
import Dog from "./Dog";
import Cat from "./Cat";
import Elephant from "./Elephant";
import Lion from "./Lion";
import Monkey from "./Monkey";
import Bird from "./Bird";
import Tiger from "./Tiger";

export default function AnimalFactory(scene: Phaser.Scene, x: number, y: number, animalName: string): AbstractAnimal {
  switch (animalName) {
    case "cachorro":
      return new Dog(scene, x, y);
    case "elefante":
      return new Elephant(scene, x, y);
    case "gato":
      return new Cat(scene, x, y);
    case "leao":
      return new Lion(scene, x, y);
    case "macaco":
      return new Monkey(scene, x, y);
    case "passaro":
      return new Bird(scene, x, y);
    case "tigre":
      return new Tiger(scene, x, y);
  }
}