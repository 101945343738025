import * as Phaser from "phaser";
import BaseButton from "../../../Commons/buttons/BaseButton";
import PlayButton from "../../../Commons/buttons/PlayButton";
import AnimalFactory from "../GameObjects/AnimalFactory";
import AbstractAnimal from "../GameObjects/AbstractAnimal";
import ParticulasFanfarra from "../../../Commons/gameObjects/ParticulasFanfarra";

export default class AnimalsScene extends Phaser.Scene {
  protected GAME_WIDTH: number;
  protected GAME_HEIGHT: number;

  protected Background: Phaser.GameObjects.Image;
  protected nextButton: BaseButton;
  protected restartButton: PlayButton;
  protected endGameButton: BaseButton;
  //protected endTitle: Phaser.GameObjects.BitmapText;
  protected endTitle: Phaser.GameObjects.Image;

  protected startPosition: number = 2500;
  protected onScreenPosition: number = 960;
  protected finalPosition: number = -1000;
  protected isTransition: boolean = false;
  protected animalGroup: Phaser.GameObjects.Group;
  protected initialAnimalIndex = 0;
  protected currentAnimalIndex: number = this.initialAnimalIndex;
  protected orderedAnimalsList: Array<string> = [
    "cachorro",
    "elefante",
    "gato",
    "leao",
    "macaco",
    "passaro",
    "tigre"
  ];

  protected tutorialAudio: Phaser.Sound.BaseSound;
  protected falaFinalTigre: Phaser.Sound.BaseSound;
  protected endNarration: Phaser.Sound.BaseSound;

  constructor() {
    super({ key: "Animais" });
  }

  init = () => {
    this.GAME_WIDTH = this.game.config.width as number;
    this.GAME_HEIGHT = this.game.config.height as number;

    this.currentAnimalIndex = this.initialAnimalIndex;
    this.isTransition = false;
  }

  preload = () => {
    this.Background = this.add.image(this.GAME_WIDTH / 2, this.GAME_HEIGHT / 2, "background");

    this.load.audioSprite("sfx_animals", "./Assets/sfx/sfx_animals_atlas.json", "./Assets/sfx/sfx_animals.mp3");
    this.load.atlas("animals_texture", "./Assets/images/animals_texture_tinifed.png", "./Assets/images/animals_texture_atlas.json");

    this.load.image("confete", "./Assets/images/confete.png");

    this.load.image("texto_fim", "./Assets/images/titulo_fim.png");

    this.load.audio("fala_2tutorial", "./Assets/dublagem/fala_2tutorial.ogg")
    this.load.audio("fala_99fim", "./Assets/dublagem/fala_99fim_01.ogg")
    this.load.audio("fala_cachorro_tutorial", "./Assets/dublagem/fala_cachorro_fim_01.ogg")
    this.load.audio("fala_cachorro", "./Assets/dublagem/fala_cachorro_01.ogg")
    this.load.audio("fala_elefante", "./Assets/dublagem/fala_elefante_01.ogg")
    this.load.audio("fala_gato", "./Assets/dublagem/fala_gato_01.ogg")
    this.load.audio("fala_leao", "./Assets/dublagem/fala_leao_01.ogg")
    this.load.audio("fala_macaco", "./Assets/dublagem/fala_macaco_01.ogg")
    this.load.audio("fala_passaro", "./Assets/dublagem/fala_passaro_01.ogg")
    this.load.audio("fala_tigre", "./Assets/dublagem/fala_tigre_01.ogg")
    this.load.audio("fala_tigre_final", "./Assets/dublagem/fala_tigre_final_01.ogg")

    this.load.audio("fala_fim", "./Assets/dublagem/fala_99fim_01.ogg")
    this.load.audio("ui_fanfarra_fim", "./Assets/sfx/ui_fanfarra_fim.ogg");

  }

  create = () => {
    this.animalGroup = this.add.group({
      classType: AbstractAnimal,
      defaultKey: "animals_texture"
    });
    const startAnimal: AbstractAnimal = AnimalFactory(this, this.onScreenPosition, 700, this.orderedAnimalsList[this.currentAnimalIndex]);
    this.animalGroup.add(startAnimal, true);

    this.nextButton = new BaseButton(this, 1650, 920, "Próximo").setDepth(10);
    this.restartButton = new PlayButton(this, (this.GAME_WIDTH / 2) - 200, this.GAME_HEIGHT / 2 - 300).setDepth(10).setVisible(false);
    this.endGameButton = new BaseButton(this, (this.GAME_WIDTH / 2) + 200, this.GAME_HEIGHT / 2 - 300, "Fim").setDepth(10).setVisible(false);

    // this.endTitle = this.add.bitmapText(this.GAME_WIDTH / 2, this.GAME_HEIGHT / 2, "black-coffe", "Parabéns! Você terminou!", 164).setVisible(false).
    //     setTint(0x42a3af).
    //     setOrigin(0.5).
    //     setDropShadow(10,10,0xffffff).
    //     setDepth(10);

    this.endTitle = this.add.sprite(this.GAME_WIDTH/2, this.GAME_HEIGHT/2, "texto_fim")
        .setVisible(false)
        .setOrigin(0.5,0.5)
        .setDepth(10)

    this.tutorialAudio = this.sound.add("fala_2tutorial");
    this.tutorialAudio.play();

    this.tutorialAudio.addListener(Phaser.Sound.Events.COMPLETE, this.handleEndTutorial);
    this.nextButton.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.nextAnimal);
    this.endGameButton.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.showConfetti);
    this.restartButton.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.restartGame);
    this.events.addListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
  }

  nextAnimal = () => {
    if (this.isTransition || this.tutorialAudio.isPlaying) return;

    if (this.currentAnimalIndex < this.orderedAnimalsList.length - 1) {
      this.switchAnimals(this.currentAnimalIndex + 1);

      this.currentAnimalIndex++
    }
    else {
      this.showEndGameScreen()
    }
  }

  showEndGameScreen = () => {
    this.falaFinalTigre = this.sound.add("fala_tigre_final");
    this.falaFinalTigre.play();

    this.restartButton.setVisible(true);
    this.endGameButton.setVisible(true);
    this.nextButton.setVisible(false);
  }

  restartGame = () => {
    this.falaFinalTigre.stop();
    this.scene.restart();
  }

  private switchAnimals(nextAnimalIndex: number) {
    this.isTransition = true;

    this.hideCurrentAnimal();

    this.showNextAnimal(nextAnimalIndex);
  }

  private hideCurrentAnimal = (): Phaser.Tweens.Tween => {
    const currentAnimal: AbstractAnimal = this.animalGroup.getFirstNth(this.currentAnimalIndex, true);

    currentAnimal.canBeClicked = false;
    currentAnimal.stopSound();

    return this.tweens.add({
      targets: currentAnimal,
      x: this.finalPosition,
      duration: 2200,
      ease: Phaser.Math.Easing.Quadratic.Out,
      onComplete: (tween, targets: Array<AbstractAnimal>) => {
        targets[0].destroy();
      }
    });
  }

  private showNextAnimal = (nextAnimalIndex: number): Phaser.Tweens.Tween => {
    let nextAnimal: AbstractAnimal = AnimalFactory(this, this.startPosition, 700, this.orderedAnimalsList[nextAnimalIndex]);
    this.animalGroup.add(nextAnimal);

    return this.tweens.add({
      targets: nextAnimal,
      x: this.onScreenPosition,
      duration: 2200,
      ease: Phaser.Math.Easing.Quadratic.In,
      onComplete: this.handleAnimalOnScreen
    });
  }

  private handleAnimalOnScreen = (tween: Phaser.Tweens.Tween, animals: Array<AbstractAnimal>): void => {
    const currentAnimal: AbstractAnimal = animals[0];

    currentAnimal.canBeClicked = true;
    currentAnimal.tryPlayNarrationSound();
    this.isTransition = false;
  }

  private handleEndTutorial = () => {
    const currentAnimal: AbstractAnimal = this.animalGroup.getFirstNth(this.currentAnimalIndex, true);
    currentAnimal.canBeClicked = true;
  }

  private showConfetti = () => {
    this.falaFinalTigre.stop();
    this.sound.play("fala_fim");

    let tween: Phaser.Tweens.Tween = this.hideCurrentAnimal();
    tween.addListener(Phaser.Tweens.Events.TWEEN_COMPLETE, () => {
      this.restartButton.setVisible(false);
      this.endGameButton.setVisible(false);
      this.endTitle.setVisible(true);

      let particles = new ParticulasFanfarra(this);
    });
  }

  cleanEvents = () => {
    this.tutorialAudio.removeListener(Phaser.Sound.Events.COMPLETE, this.handleEndTutorial);
    this.restartButton.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.restartGame);
    this.nextButton.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.nextAnimal);
    this.events.removeListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
  }
}
