export default class PlayButton extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "buttons-texture", "play-1");

    this.scene.add.existing(this);

    this.setInteractive(new Phaser.Geom.Circle(300, 300, 120), Phaser.Geom.Circle.Contains);

    if (process.env.NODE_ENV === 'development') {
      this.scene.input.enableDebug(this);
    }

    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.pointerOver);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.pointerNormal);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.pointerDown);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.pointerNormal);
    // this.scene.events.addListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
    this.addListener(Phaser.GameObjects.Events.DESTROY, this.cleanEvents);
  }

  private pointerNormal = () => {
    this.setFrame("play-1");
  }

  private pointerOver = () => {
    this.setFrame("play-2");
  }

  private pointerDown = () => {
    this.setFrame("play-3");
    this.scene.sound.play("ui_button");
  }

  private cleanEvents = (sys: Phaser.Scenes.Systems) => {
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.pointerOver);
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.pointerDown);
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.pointerNormal);
    this.removeListener(Phaser.GameObjects.Events.DESTROY, this.cleanEvents);
  }
}