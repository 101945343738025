import AbstractAnimal from "./AbstractAnimal";
import HasTutorial from "./HasTutorial";

export default class Dog extends AbstractAnimal implements HasTutorial {
  protected tutorialNarration: Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "cachorro");

    this.volume = 1;
    this.narrationSound = this.scene.sound.add("fala_cachorro");
    this.tutorialNarration = this.scene.sound.add("fala_cachorro_tutorial");
    this.setName("cachorro");

    this.animalSound.addListener(Phaser.Sound.Events.COMPLETE, this.tryPlayTutorialNarration);
  }

  public tryPlayTutorialNarration = (): void => {
    if (this.tutorialNarration === null || this.tutorialNarration === undefined || this.hasPlayedTutorialNarration === true || this.canBeClicked === false) return

    this.tutorialNarration.play();
    this.hasPlayedTutorialNarration = true;
  }

  public stopSound(): void {
    super.stopSound();

    this.tutorialNarration?.stop();
  }

  protected cleanEvents(sys: Phaser.Scenes.Systems) {
    super.cleanEvents(sys);
    
    this.animalSound.removeListener(Phaser.Sound.Events.COMPLETE, this.tryPlayTutorialNarration);
    this.tutorialNarration.destroy();
  }
}