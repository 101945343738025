import AbstractAnimal from "./AbstractAnimal";

export default class Elephant extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "elefante");

    this.volume = 0.8;
    this.narrationSound = this.scene.sound.add("fala_elefante");
    this.setName("elefante");
  }
}