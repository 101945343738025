import * as Phaser from "phaser";
import PlayButton from "../../../Commons/buttons/PlayButton"

export default class Menu extends Phaser.Scene {
  protected GAME_WIDTH: number;
  protected GAME_HEIGHT: number;
  protected Background: Phaser.GameObjects.Image;
  protected Title: Phaser.GameObjects.Image;
  protected playBtn: PlayButton;
  protected apresentacaoAudio: Phaser.Sound.BaseSound;
  private backgroundMusic: Phaser.Sound.BaseSound;

  constructor() {
    super({ key: "Menu" });
  }

  protected init = (): void => {
    this.GAME_WIDTH = this.game.config.width as number;
    this.GAME_HEIGHT = this.game.config.height as number;
  }

  protected preload = (): void => {
    this.load.audio("fala_1apresentacao","./Assets/dublagem/fala_1apresentacao.ogg");
    this.load.audio("background-music", "./Assets/music/soundscape-jogo-1-loop.ogg");
    this.load.audio("ui_button", "./Assets/sfx/ui_clique.ogg");
    
    this.load.image("background", "./Assets/images/background/background_tinifed.png");
    this.load.image("title", "./Assets/images/titulo_tinifed.png");

    this.load.atlas("buttons-texture", "./Assets/buttons/buttons_texture.png", "./Assets/buttons/buttons_texture.json");
    this.load.bitmapFont("black-coffe", "./Assets/fonts/black-coffe/black-coffe-bitmap_0.png", "./Assets/fonts/black-coffe/black-coffe-bitmap.xml");
  }

  protected create = (): void => {
    this.sound.pauseOnBlur = false;
    this.Background = this.add.image(this.GAME_WIDTH / 2, this.GAME_HEIGHT / 2, "background");
    this.Title = this.add.image(500, 150, "title").setScale(0.9, 1);
    
    this.playBtn = new PlayButton(this, this.GAME_WIDTH / 2, this.GAME_HEIGHT / 2);

    this.playBtn.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.startGame);
    this.events.addListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);

    this.apresentacaoAudio = this.sound.add("fala_1apresentacao");
    this.apresentacaoAudio.play()

    this.backgroundMusic = this.sound.add("background-music");
    this.backgroundMusic.play({
      loop: true
    });
  }

  protected startGame = (): void => {
    this.apresentacaoAudio.stop();
    this.scene.start("Animais");
  }

  protected cleanEvents = (): void => {
    this.playBtn.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.startGame);
    this.events.removeListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
  }
}