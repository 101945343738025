import * as Phaser from "phaser";

export default abstract class AbstractAnimal extends Phaser.GameObjects.Image {
  protected animalTween: Phaser.Tweens.Timeline;

  public canBeClicked: boolean = false;
  protected hasPlayedTutorialNarration: boolean = false;

  protected animalSound: Phaser.Sound.BaseSound;
  protected narrationSound: Phaser.Sound.BaseSound;

  protected volume: number;
  protected initialVariation: number = 0;
  protected variationIndex: number = this.initialVariation;
  protected variationList: Array<number> = [1, 2, 3, 4];

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: string | number) {
    super(scene, x, y, texture, frame);

    this.scene.add.existing(this);

    const inputConfiguration: Phaser.Types.Input.InputConfiguration = {
      useHandCursor: true
    }
    this.setInteractive(inputConfiguration);
    this.animalSound = this.scene.sound.addAudioSprite("sfx_animals");

    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.handleClick);
    this.addListener(Phaser.GameObjects.Events.DESTROY, this.cleanEvents);
  }

  protected handleClick = (): void => {
    if (this.canBeClicked === false || this.animalSound.isPlaying === true || this.narrationSound.isPlaying === true) return;

    if (this.variationIndex >= this.variationList.length) {
      this.variationIndex = this.initialVariation;
      Phaser.Utils.Array.Shuffle(this.variationList);
    }

    const variationIndex: number = this.variationList[this.variationIndex];

    this.animalSound.play(`${this.name}_${variationIndex}`, {
      volume: this.volume,
      detune: Phaser.Math.Between(-200, 200)
    });
    this.animationFeedback();

    this.variationIndex++;
  }

  public tryPlayNarrationSound = (): void => {
    if (this.narrationSound === null || this.narrationSound === undefined || this.canBeClicked === false) return

    this.narrationSound.play();
  }

  public animationFeedback = (): void => {
    const animalSoundDuration: number = Math.floor(this.animalSound.duration * 1000);

    this.animalTween = this.scene.tweens.timeline({
      targets: this,
      ease: "Power1",

      // Por algum motivo o atributo duration não leva em consideração
      // a propriedade yoyo, então devemos individualmente dizer a duracacao
      // de cada tween... como são 4 estados, cada um receberar 1 / 4 do tempo total
      tweens: [
        {
          scale: 1.05,
          ease: "Cubic",
          duration: animalSoundDuration / 4,
          yoyo: false
        },
        {
          angle: 7,
          duration: (animalSoundDuration / 4) / 2, // Nesse momento precisamos dividir por 2, pois, ele leva x tempo para ir e para voltar, totalizando 2x.
          yoyo: true
        },
        {
          angle: -7,
          duration: (animalSoundDuration / 4) / 2,
          yoyo: true
        },
        {
          angle: 0,
          scale: 1,
          duration: animalSoundDuration / 4,
          yoyo: false
        }
      ]
    })
  }

  public stopSound(): void {
    this.animalSound?.stop();
    this.narrationSound?.stop();
  }

  protected cleanEvents(sys: Phaser.Scenes.Systems): void {
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.handleClick);
    sys.scene.events.removeListener("playTutorialSound", this.tryPlayNarrationSound);
    sys.scene.events.removeListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);

    // Referencias à objetos que ainda estariam registrados na cena.
    this.animalSound?.destroy();
    this.narrationSound?.destroy();
    this.animalTween?.destroy();
  }
}