import AbstractAnimal from "./AbstractAnimal";

export default class Tiger extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "tigre");

    this.volume = 0.5;
    this.narrationSound = this.scene.sound.add("fala_tigre");
    this.setName("tigre");
  }
}