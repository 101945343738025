import AbstractAnimal from "./AbstractAnimal";

export default class Lion extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "leao");

    this.volume = 1;
    this.narrationSound = this.scene.sound.add("fala_leao");
    this.setName("leao");
  }
}