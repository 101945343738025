export default class BaseButton extends Phaser.GameObjects.Container {
  private background: Phaser.GameObjects.Image;
  private text: Phaser.GameObjects.BitmapText;

  constructor(scene: Phaser.Scene, x: number, y: number, text: string) {
    super(scene, x, y);

    this.scene.add.existing(this);

    this.background = this.scene.add.image(0, 0, "buttons-texture", "base-1").setScale(2.5);
    this.text = this.scene.add.bitmapText(0, 0, "black-coffe", text, 124).setOrigin(0.5);

    this.setInteractive(new Phaser.Geom.Rectangle(-this.background.displayWidth / 2, -this.background.displayHeight / 2, this.background.displayWidth, this.background.displayHeight), Phaser.Geom.Rectangle.Contains);
    
    if (process.env.NODE_ENV === 'development') {
      this.scene.input.enableDebug(this);
    }

    this.add([this.background, this.text]);

    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.pointerOver);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.pointerNormal);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.pointerDown);
    this.addListener(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.pointerNormal);
    this.scene.events.addListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
  }

  private pointerNormal = () => {
    this.background.setFrame("base-1");
  }
  
  private pointerOver = () => {
    this.background.setFrame("base-2");
  }
  private pointerDown = () => {
    this.background.setFrame("base-3");
    this.scene.sound.play("ui_button");
  }
  
  cleanEvents = (sys: Phaser.Scenes.Systems) => {
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.pointerOver);
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.pointerNormal);
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.pointerDown);
    this.removeListener(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.pointerNormal);
    sys.scene.events.addListener(Phaser.Scenes.Events.SHUTDOWN, this.cleanEvents);
  }
}