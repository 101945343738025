import AbstractAnimal from "./AbstractAnimal";

export default class Monkey extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "macaco");

    this.volume = 1;
    this.narrationSound = this.scene.sound.add("fala_macaco");
    this.setName("macaco");
  }
}