import AbstractAnimal from "./AbstractAnimal";

export default class Cat extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "gato");

    this.volume = 1;
    this.narrationSound = this.scene.sound.add("fala_gato");
    this.setName("gato");
  }
}