export default class ParticulasFanfarra extends Phaser.GameObjects.Particles.ParticleEmitterManager {
  protected GAME_WIDTH: number;
  protected GAME_HEIGHT: number;

  protected particleSpeed: number = 45;
  protected particleLifespan: number = 10_000;
  protected particleScale: number = 0.15;
  protected emitZoneShape: Phaser.Geom.Rectangle;
  protected speed: number = 950;
  protected gravity: number = 400;
  protected scaleMin: number = 0.15;
  protected scaleMax: number = 0.25;

  constructor(scene: Phaser.Scene) {
    super(scene, "confete");

    this.scene.add.existing(this);

    this.scene.sound.play("ui_fanfarra_fim", { volume: 0.4 });

    this.GAME_WIDTH = Number(this.scene.game.config.width);
    this.GAME_HEIGHT = Number(this.scene.game.config.height);

    this.CreateConfeteParticles(0xc95212);
    this.CreateConfeteParticles(0x1298c9);
    this.CreateConfeteParticles(0xe744e6);
  }

  private CreateConfeteParticles(color: number) {
    this.emitZoneShape = new Phaser.Geom.Rectangle(0, 0, 20, this.GAME_HEIGHT / 3);
    this.emitZoneShape.centerX = 0;
    this.emitZoneShape.centerY = 0.5;

    let defaultParticleConfig: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
      x: 0,
      y: this.GAME_HEIGHT * 0.75,
      angle: { min: -5, max: -45 },
      speed: this.speed,
      gravityY: this.gravity,
      lifespan: this.particleLifespan,
      quantity: 1,
      scaleX: {
        onEmit: () => {
          return Phaser.Math.FloatBetween(this.scaleMin, this.scaleMax);
        },
        onUpdate: (particle: Phaser.GameObjects.Particles.Particle, property: string, t: number, value: number): number => {
          let result = value;
          result += 0.01;
    
          if (result > this.scaleMax) {
            result = -this.scaleMin;
          }
    
          return result;
        }
      },
      scaleY: {
        onEmit: (particle: Phaser.GameObjects.Particles.Particle, property: string, value: number) => {
          return Phaser.Math.FloatBetween(this.scaleMin, this.scaleMax);
        },
      },
      rotate: {
        onEmit: () => {
          return Phaser.Math.Between(0, 360)
        },
        onUpdate: (particle) => {
          return particle.angle + 1
        },
      },
      emitZone: { type: 'random', source: this.emitZoneShape },
    }

    const emitter1: Phaser.GameObjects.Particles.ParticleEmitter = this.createEmitter(defaultParticleConfig);
    // emitter1.tint.onChange(color);
    emitter1.setTint(color);
    emitter1.flow(25);

    defaultParticleConfig.x = this.GAME_WIDTH;
    defaultParticleConfig.angle = {
      min: 185,
      max: 225
    }
    const emitter2: Phaser.GameObjects.Particles.ParticleEmitter = this.createEmitter(defaultParticleConfig);
    // emitter2.tint.onChange(color);
    emitter2.setTint(color);
    emitter2.flow(25);

  }
}