import AbstractAnimal from "./AbstractAnimal";

export default class Bird extends AbstractAnimal {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "animals_texture", "passaro");

    this.volume = 1;
    this.narrationSound = this.scene.sound.add("fala_passaro");
    this.setName("passaro");
  }
}