import * as Phaser from "phaser";
import Menu from "./Scenes/Menu";
import Animals from "./Scenes/Animals";

const GAME_WIDTH: number = 1920;
const GAME_HEIGHT: number = 1080;

const config: Phaser.Types.Core.GameConfig = {
  title: "Conected Bebês - Animais",
  width: GAME_WIDTH,
  height: GAME_HEIGHT,
  type: Phaser.AUTO,
  parent: "game-screen",
  autoFocus: true,
  banner: true,
  scene: [Menu, Animals],
  dom: {
    createContainer: true
  },
  scale: {
    width: GAME_WIDTH,
    height: GAME_HEIGHT,
    parent: "game-screen",
    mode: Phaser.Scale.ScaleModes.FIT,
    autoRound: true,
    autoCenter: Phaser.Scale.Center.CENTER_BOTH,
    fullscreenTarget: "game-screen"
  }
};

const game = new Phaser.Game(config);